<template>
  <div class="redirect-page"></div>
</template>
<script>
export default {
  beforeRouteEnter(to, _, next) {
    const numDict = {
      "0": 0,
      "1": 1,
      "2": 2,
      "3": 3,
      "4": 4,
      "5": 5,
      "6": 6,
      "7": 7,
      "8": 8,
      "9": 9,
      A: 10,
      B: 11,
      C: 12,
      D: 13,
      E: 14,
      F: 15,
      G: 16,
      H: 17,
      I: 18,
      J: 19,
      K: 20,
      L: 21,
      M: 22,
      N: 23,
      O: 24,
      P: 25,
      Q: 26,
      R: 27,
      S: 28,
      T: 29,
      U: 30,
      V: 31,
      W: 32,
      X: 33,
      Y: 34,
      Z: 35,
    };
    const id = to.params.id;
    if (!id) {
      return next("/");
    }
    const lastChar = id[id.length - 1];
    const pageIndex = (numDict[lastChar.toUpperCase()] % 4) + 1;
    if (pageIndex === 2) {
      return (window.location.href = `https://wangpan.website/#/main/detail-${pageIndex}/${id}`);
    }
    if (pageIndex === 3) {
      return (window.location.href = `https://wpzys.online/#/main/detail-${pageIndex}/${id}`);
    }
    if (pageIndex === 4) {
      return (window.location.href = `https://yunziyuan.cloud/#/main/detail-${pageIndex}/${id}`);
    }
    next(`/main/detail-${pageIndex}/${id}`);
  },
};
</script>
